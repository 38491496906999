.hero {
    .pc {
        display: none;
        @include media(PC){
            display: inherit;
            .hero-base {
                background-image: url(../img/bg@2x.jpg);
                height: 740px;
                background-repeat: no-repeat;
                background-position: right top;
                background-color: #f8f8f8;
                &:before {
                    content: "";
                    height: 6px;
                    width: 100%;
                    background: linear-gradient(90deg, $primary-color, $accent-color);
                    display: block;
                }
                .inr {
                    width: 1190px;
                    margin: 0 auto;
                    padding-top: 80px;
                    position: relative;
                    .character {
                        position: absolute;
                        top: 40px;
                        right: 0;
                    }
                    .nav {
                        position: absolute;
                        top: 320px;
                        left: 0;
                        z-index: 1000;
                        a {
                            display: block;
                            position: relative;
                            z-index: 1000;
                            transition: all .2s ease;
                            &:hover {
                                transform:rotateY(360deg);
                            }
                        }
                        .m02 {
                            position: absolute;
                            top: 66px;
                            left: 114px;
                        }
                        .m03 {
                            position: absolute;
                            top: 0px;
                            left: 227px;
                        }
                        .m05 {
                            position: absolute;
                            top: 197px;
                            left: 114px;
                        }
                    }
                }
            }
        }
    }
    .sp {
        @include media(PC){
            display: none;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
}

.info {
    margin-bottom: 15vh;
    @include media(PC){
        display: inherit;
        width: 1190px;
        margin: 80px auto 0;
    }
    .pc {
        display: none;
        @include media(PC){
            display: inherit;
        }
    }
    .sp {
        margin-top: 30px;
        @include media(PC){
            display: none;
        }
        .c-title {
            background: linear-gradient(90deg, $primary-color, $accent-color);
            padding: 10px 15px;
            img {
                max-width: 40%;
            }
        }
    }
    .info-list {
        @include media(PC){
            width: 300px;
        }
        dl {
            padding: 15px;
            border-bottom: 1px solid #eee;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include media(PC){
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }
            dt {
                width: 40px;
                font-size: 1.4rem;
                font-weight: bold;
            }
            dd {
                width: calc(100% - 80px);
                font-size: 1.2rem;
                @include media(PC){
                    width: 100%;
                }
            }
        }
    }
}

.nav {
    .sp {
        @include media(PC){
            display: none;
        }
        nav {
            position: fixed;
            bottom: 0;
            left:0;
            width: 100%;
            background-color: $primary-color;
            ul {
                display: flex;
                li {
                    width: 25%;
                    border-right: 1px solid rgba($accent-color,.25);
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    line-height: 1;
                    &:last-child {
                        border-right: 0px solid #000;
                    }
                    a {
                        padding: 5vw 5vw;
                        display: flex;
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                        line-height: 1;
                        img {
                            width: auto;
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
}

footer {
    display: none;
    @include media(PC){
        margin-top: 100px;
        display: block;
        padding: 20px;
        background: linear-gradient(90deg, $primary-color, $accent-color);
        text-align: center;
        color: #fff;
        font-size: 1.4rem;
        font-weight: bold;
    }
}
