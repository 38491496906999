//-----------------------------------------------------------------------------------
//
//  common.scss
//
//  1. font style
//  2. layout style
//
//-----------------------------------------------------------------------------------

//////////////////////////////////////////////////////////
// 1. font style
//////////////////////////////////////////////////////////

html,body {
    font-size: 62.5%;
    font-family: $body-font;
    color: $dark-color;
    line-height: $line-height;
    -webkit-font-smoothing: antialiased;
    @include media(PC){
        min-width: 1190px;
    }
}

::selection {
    background: $primary-color;
    color: $light-color;
    text-shadow: none;
}



